const integrationlist = [
  { name: 'Microsoft Azure AD', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Azure.svg' },
  { name: 'Google Workspace', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Google.svg' },
  { name: 'OneLogin', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_oneLogin.svg' },
  { name: 'Okta', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_okta.svg' },
  { name: 'Microsoft Dynamics', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Microsoft Dynamic.svg' },
]

export const CommunicationList = [
  { name: 'SMS Notification', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_SMS Notification.svg' },
  { name: 'Zoom', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Zoom.svg' },
  { name: 'Whatsapp', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Whatsapp.svg' },
  { name: 'Slack', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Slack.svg' },
]

export const RecruitmentList = [
  { name: 'Breezy', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_breezy.svg' },
  { name: 'Indeed', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_indeed.svg' },
  { name: 'Dr Job', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_DrJob.svg' },
  { name: 'Eluta', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_eluta.svg' },
  { name: 'JazzHR', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_JazzHR.svg' },
  { name: 'MyJobHelper', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_MyJobHelper.svg' },
  { name: 'Zip Recruiter', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_ZipRecruiter.svg' },
  { name: 'Recruit', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Recruit.svg' },
]
export const otherIntegrations = [
  { name: 'Asana', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Asana.svg' },
  { name: 'bonusly', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Bonusly.svg' },
  { name: 'Box', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Box.svg' },
  { name: 'Dropbox', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Dropbox.svg' },
  // { name: 'Glassdoor', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Glassdoor.svg' },
  { name: 'FreshBooks', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_FreshBooks.svg' },
  { name: 'LinkedIn', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_LinkedIN.svg' },
  { name: 'Neuvoo', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Neuvoo.svg' },
  { name: 'Quickbook', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_QuickBook.svg' },
  { name: 'Sure Payroll', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_surePayroll.svg' },
  { name: 'Xero', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo_Xero.svg' },
  { name: 'SAML For SSO', logo: 'https://cdn.webhr.co/Website/images/integrations/Int_Logo-23.svg' },
]

export const getAllIntegrations = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(integrationlist), 100)
  })
}
export const getAllCommunications = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(CommunicationList), 100)
  })
}
