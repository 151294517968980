import React from 'react'
import Link from 'next/link'
import { Container } from 'react-bootstrap'
import styles from '../../styles/Home.module.css'
import { useWindowSize } from '../../hooks/WindowSize'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Image from 'next/image'
import {Row, Col} from 'react-bootstrap'

const Badges = () => {
  const { width } = useWindowSize()
  return (
    <div className={styles.homeMobileContainer} style={{ paddingBlock: 100 }}>
      <Container>
        <div className={styles.newBadgeSection}>
          <h2 style={{ fontSize: 45, fontFamily: 'EnzoLight', fontWeight: 'bold' }}>
            <span style={{ fontWeight: 700, fontFamily: 'EnzoBold', color: '#FF9505' }}>Trusted </span> and <span style={{ fontWeight: 700, fontFamily: 'EnzoBold', color: '#FF9505' }}>Recognized</span> HR Software
          </h2>

          <div style={{ marginBlock: width < 1330 ? 30 : 70, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Link target="_blank" href={'https://www.getapp.com/hr-employee-management-software/a/web-human-resource-webhr/'}>
              <LazyLoadImage src="https://cdn.webhr.co/Website/images/reviews_badges/get_app.svg" style={{ paddingRight: width < 1330 ? 10 : 40 }} width={width < 1330 && '100%'} />
            </Link>
            <Link target="_blank" href={'https://www.softwareadvice.com/hr/webhr-profile/'}>
              <LazyLoadImage src="https://cdn.webhr.co/Website/images/reviews_badges/software_advice.svg" style={{ paddingRight: width < 1330 ? 10 : 40 }} width={width < 1330 && '100%'} />
            </Link>
            <Link target="_blank" href={'https://www.capterra.com/p/118042/WebHR/'}>
              <LazyLoadImage src="https://cdn.webhr.co/Website/images/reviews_badges/capterra.svg" style={{ paddingRight: width < 1330 ? 10 : 40 }} width={width < 1330 && '100%'} />
            </Link>
            <Link target="_blank" href={'https://www.trustpilot.com/review/webhr.co'}>
              <LazyLoadImage src="https://cdn.webhr.co/Website/images/reviews_badges/trust_pilot.svg" style={{ paddingRight: width < 1330 ? 10 : 40 }} width={width < 1330 && '100%'} />
            </Link>
            <Link target="_blank" href={'https://www.g2.com/products/webhr/reviews'}>
              <LazyLoadImage src="https://cdn.webhr.co/Website/images/reviews_badges/g2.svg" style={{ paddingRight: width < 1330 ? 10 : 40 }} width={width < 1330 && '100%'} />
            </Link>
          </div>
            <Row style={{ display:"flex", justifyContent:"space-between" }}>
              <LazyLoadImage src="https://cdn.webhr.co/Website/images/reviews_badges/online_HR_software_badges.webp" style={{ paddingRight: width < 1330 ? 10 : 40 }} width={width < 1330 && '100%'} className="d-none d-md-block" />
              <LazyLoadImage src="https://cdn.webhr.co/Website/images/reviews_badges/online_HR_software_badges_small.webp" style={{ paddingRight: width < 1330 ? 10 : 40 }} width={width < 1330 && '100%'} className="img-fluid d-xl-none d-lg-none d-md-none d-block" />
            </Row>
        </div>
      </Container>
    </div>
  )
}

export default Badges
