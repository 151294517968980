import React from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import data from './schemaData/data.json'
import { getAllIntegrations } from '../data/integrations'
import { getAllClients } from '../data/clients'
import { getAllModule } from '../data/modulesData'
import AdditionalScripts from '../utils/AdditionalScripts'
import Badges from '../components/Home/Badges'
import { useWindowSize } from '../hooks/WindowSize'
import Script from 'next/script'

const Banner = dynamic(() => import('../components/Home/NewBanner'))
const Clients = dynamic(() => import('../components/Home/Clients'))
const Intro = dynamic(() => import('../components/Home/Intro'))
const IntegrationCard = dynamic(() => import('../components/Home/IntegrationCard'))
const OldNew = dynamic(() => import('../components/Home/NewModules'))
const Mobile = dynamic(() => import('../components/Home/Mobile'))
const QuickModule = dynamic(() => import('../components/Home/QuickModule'))
const Testimonial = dynamic(() => import('../components/Home/Testimonial'))
const CommonDemo = dynamic(() => import('@/components/Common/commonDemo'))

const Index = (props) => {
  const { width } = useWindowSize()
  return (
    <>
      <Head>
        <AdditionalScripts />
      </Head>
      <Script
        id="scriptID"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(data?.productData),
        }}
      />
      <Script
        id="scriptID"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(data?.localBusinessData),
        }}
      />
      <Script
        id="scriptID"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(data?.itemListData),
        }}
      />
      <Banner />
      <Clients />
      <Badges />
      <IntegrationCard />
      <Intro />
      <QuickModule />
      <OldNew />
      <Testimonial />
      <CommonDemo />
      {/* <Mobile /> */}
    </>
  )
}

Index.getInitialProps = async () => {
  const integrations = await getAllIntegrations()
  const clients = await getAllClients()
  const allModules = await getAllModule()
  return { integrations, clients, allModules }
}

export default Index
