export const clientslist1 = [
  { name: 'Cushman & Wakefield', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_23.gif' },
  { name: 'costaCoffee', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_1.gif' },
  { name: 'embassyOfCanada', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_2.gif' },
  { name: 'jiffyLube', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_3.gif' },
  { name: 'bose', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_4.gif' },
  { name: 'payactive', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_5.gif' },
  { name: 'nissan', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_6.gif' },
  { name: 'volvo', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_7.gif' },
  { name: 'infiniti', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_8.gif' },
  { name: 'alMasaood', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_9.gif' },
  { name: 'renault', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_10.gif' },
  { name: 'versionWireless', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_11.gif' },
  { name: 'thinkKitchet', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_12.gif' },
  { name: 'bridgeStone', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_13.gif' },
  { name: 'accreditedBusiness', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_14.gif' },
  { name: 'toyota', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_15.gif' },
  { name: 'chianMotors', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_16.gif' },
  { name: 'SIRA', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_17.gif' },
  { name: 'arabica', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_18.gif' },
  { name: 'ducati', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_19.gif' },
  { name: 'usenza', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_20.gif' },
  { name: 'Hitachi Construction Machinery', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_21.gif' },
  // { name: 'webhr', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set1/logo_22.gif' },
]

export const clientslist2 = [
  { name: 'aspinal', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_2.gif' },
  { name: 'GRC', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_3.gif' },
  { name: 'ShellTechWorks', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_4.gif' },
  { name: 'embassayOfBrazill', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_5.gif' },
  { name: 'styleTextile', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_6.gif' },
  { name: 'GENG', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_7.gif' },
  { name: 'arandaaz', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_8.gif' },
  { name: 'nocTech', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_9.gif' },
  { name: 'RCG', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_10.gif' },
  { name: 'cravia', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_11.gif' },
  { name: 'expensya', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_12.gif' },
  { name: 'pico', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_13.gif' },
  { name: 'beriut', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_14.gif' },
  { name: 'graana', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_15.gif' },
  { name: 'UniversityOfDubai', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_16.gif' },
  { name: 'pragmatic', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_17.gif' },
  { name: 'mindStorm', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_18.gif' },
  { name: 'TPS', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_19.gif' },
  { name: 'Mitsubishi Heavy Industries', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_21.gif' },
  { name: 'webhr', logo: 'https://cdn.webhr.co/Website/images/client_logos/Set2/logo_22.gif' },
  
]

export const getAllClients = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(clientslist1, clientslist2), 50)
  })
}
